import React, {useEffect, useState} from "react";
import {MFantasyLeague} from "../../model/entities/MFantasyLeague";
import {MFantasyManager} from "../../model/entities/MFantasyManager";
import DraftTablesComponent from "../fantasy/components/DraftTables.component";
import {range} from "../../utilities";
import {DraftPickTradeBody} from "../../model/requests/DraftPickTradeBody";
import {MFantasyDraftPick} from "../../model/entities/MFantasyDraftPick";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import {FantasyService} from "../../service/FantasyService";
import {Logger} from "../../../Logger";
import {Alert} from "../../../Alert";
import PickTradesComponent from "../fantasy/components/PickTrades.component";

const stepOneLeagueInput = 'league-input';
const stepOneLeagueDefault = 'league-input-default';
const stepOneYearInput = 'year-input';
const stepOneYearDefault = 'year-input-default';
const stepTwoSenderInput = 'sender-input';
const stepTwoSenderDefault = 'sender-input-default';
const stepThreePickInput = 'pick-input';
const stepThreePickDefault = 'pick-input-default';
const stepThreeReceiverInput = 'receiver-input';
const stepThreeReceiverDefault = 'receiver-input-default';
const stepSubmitNotesInput = 'notes-input';
const stepSubmitConditionalInput = 'conditional-input';
const resetButton = 'reset-button';

function fetchDraftPicks(leagueId: string, managerId: string, year: string, setDraftPicks: ISetDraftPicks) {
    FantasyLeaguesService.getDraftPicksForManager(leagueId, managerId, 6, year).then(response => response.json()).then((json: MFantasyDraftPick[]) => {
        setDraftPicks(json);
    });
}

function fetchLeagues(leagues: MFantasyLeague[] | undefined, setLeagues: ISetLeagues) {
    FantasyLeaguesService.getAllLeagues().then(response => response.json()).then(json => {
        setLeagues(json.filter((item: MFantasyLeague) => (item.hasDraftBoard)));
    });
}

function fetchManagers(leagueId: string, setManagers: ISetManagers) {
    FantasyLeaguesService.getAllManagers(leagueId).then(response => response.json()).then(json => {
        setManagers(json);
    });
}

function renderNewTradeStepOne(leagues: MFantasyLeague[], selectedLeagueId: string | undefined, setSelectedLeagueId: ISetSelectedLeagueId, setYear: ISetYear) {
    const selectHandlerLeague = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLeagueId(e.target.value);
        e.target.disabled = true;
    };

    const selectHandlerYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setYear(e.target.value);
        e.target.disabled = true;
    };

    return (
        <>
            <select id={stepOneLeagueInput} onChange={selectHandlerLeague}>
                <option id={stepOneLeagueDefault} disabled={true} key={null} selected={true} value={undefined}>Select League</option>
                {leagues.map((item: MFantasyLeague, i: number) => {
                    return <option key={i} value={item.id}>{item.name} ({item.id})</option>
                })}
            </select>
            <select id={stepOneYearInput} onChange={selectHandlerYear}>
                <option id={stepOneYearDefault} disabled={true} key={null} selected={true} value={undefined}>Select Year</option>
                {range(2010, 2030).map((item: number, i: number) => {
                    return <option key={i} selected={false} value={item.toString()}>{item}</option>
                })}
            </select>
        </>
    );
}

function resetStepOne(setSelectedLeagueId: ISetSelectedLeagueId, setYear: ISetYear) {
    setSelectedLeagueId(undefined);
    (document.getElementById(stepOneLeagueDefault) as HTMLOptionElement).selected = true;
    (document.getElementById(stepOneLeagueInput) as HTMLInputElement).disabled = false;
    setYear(undefined);
    (document.getElementById(stepOneYearDefault) as HTMLOptionElement).selected = true;
    (document.getElementById(stepOneYearInput) as HTMLInputElement).disabled = false;
}

function renderNewTradeStepTwo(managers: MFantasyManager[], setSelectedSenderId: ISetSelectedManagerId) {
    const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSenderId(e.target.value);
        e.target.disabled = true;
    };

    return (
        <div>
            <select id={stepTwoSenderInput} onChange={selectHandler}>
                <option id={stepTwoSenderDefault} disabled={true} key={null} selected={true} value={undefined}>Select Sending Manager</option>
                {managers.map((item: MFantasyManager, i: number) => {
                    return <option key={i} value={item.id}>{item.displayName} ({item.id})</option>
                })}
            </select>
        </div>
    );
}

function resetStepTwo(setSelectedSenderId: ISetSelectedManagerId) {
    setSelectedSenderId(undefined);
    let senderInput = document.getElementById(stepTwoSenderInput) as HTMLInputElement;
    let senderDefault = document.getElementById(stepTwoSenderDefault) as HTMLOptionElement;
    if (senderInput && senderDefault) {
        senderInput.disabled = false;
        senderDefault.selected = true;
    }
}

function renderNewTradeStepThree(sendingManagerId: string, picks: MFantasyDraftPick[], setSelectedSendingPick: ISetSelectedPick, managers: MFantasyManager[], setSelectedReceivingId: ISetSelectedManagerId, ) {

    let pickLookup = new Map<string, MFantasyDraftPick>();
    picks.forEach((p: MFantasyDraftPick) => pickLookup.set(encodeStr(p), p));

    function encodeStr(pick: MFantasyDraftPick) {
        return `${pick.year}.${pick.round}.${pick.pickNumber}`;
    }

    function displayStr(pick: MFantasyDraftPick) {
        let originalManager;
        if (pick.draftPickTrade !== null && pick.draftPickTrade.viaManager !== null) {
            originalManager = pick.draftPickTrade.viaManager;
        } else if (pick.draftPickTrade !== null && pick.draftPickTrade.viaManager === null) {
            originalManager = pick.draftPickTrade.sendingManager;
        } else {
            originalManager = pick.manager;
        }

        return `${encodeStr(pick)} (${originalManager.displayName})`;
    }

    const selectHandlerPick = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedSendingPick(pickLookup.get(e.target.value));
        e.target.disabled = true;
    };

    const selectHandlerReceiving = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedReceivingId(e.target.value);
        e.target.disabled = true;
    };

    return (
        <>
            <select id={stepThreePickInput} onChange={selectHandlerPick}>
                <option id={stepThreePickDefault} disabled={true} key={null} selected={true} value={undefined}>Select Draft Pick</option>
                {picks.map((item: MFantasyDraftPick, i: number) => {
                    return <option key={i} value={encodeStr(item)}>{displayStr(item)}</option>
                })}
            </select>
            <select id={stepThreeReceiverInput} onChange={selectHandlerReceiving}>
                <option id={stepThreeReceiverDefault} disabled={true} key={null} selected={true} value={undefined}>Select Receiving Manager</option>
                {managers.map((item: MFantasyManager, i: number) => {
                    return <option disabled={item.id.toString() === sendingManagerId} key={i} value={item.id}>{item.displayName} ({item.id})</option>
                })}
            </select>
        </>
    );
}

function resetStepThree(setPick: ISetSelectedPick, setReceiverId: ISetSelectedManagerId) {
    setPick(undefined);
    let pickInput = document.getElementById(stepThreePickInput) as HTMLInputElement;
    let pickDefault = document.getElementById(stepThreePickDefault) as HTMLOptionElement;
    if (pickInput && pickDefault) {
        pickInput.disabled = false;
        pickDefault.selected = true;
    }

    setReceiverId(undefined);
    let receiverInput = document.getElementById(stepThreeReceiverDefault) as HTMLOptionElement;
    let receiverDefault = document.getElementById(stepThreeReceiverInput) as HTMLInputElement;
    if (receiverInput && receiverDefault) {
        receiverInput.selected = true;
        receiverDefault.disabled = false;
    }
}

function renderNewTradeSubmit(selectedSenderId: string, selectedSendingPick: MFantasyDraftPick, selectedReceiverId: string, notes: string | null,
                              setNotes: ISetNotes, isConditional: boolean, setIsConditional: ISetBool, setYear: ISetYear,
                              toggle: number, setToggle: ISetToggle, setResetToggle: ISetToggle
) {

    const textHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value);
    }

    const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsConditional(e.target.checked);
    }

    const buttonHandler = (e: React.MouseEvent<HTMLInputElement>) => {
        validateAndSubmit(selectedSenderId, selectedSendingPick, selectedReceiverId, notes, isConditional, toggle, setToggle, setResetToggle);
    }

    function validateAndSubmit(selectedSenderId: string, selectedSendingPick: MFantasyDraftPick, selectedReceiverId: string, notes: string | null, isConditional: boolean, toggle: number, setToggle: ISetToggle, setResetToggle: ISetToggle) {

        let originalManager = selectedSendingPick.draftPickTrade ? selectedSendingPick.draftPickTrade.sendingManager : selectedSendingPick.manager;
        let viaManager = selectedSendingPick.draftPickTrade ? selectedSendingPick.manager : null;

        let newTrade: DraftPickTradeBody = {
            isConditional: isConditional,
            notes: notes,
            pickRound: selectedSendingPick.round,
            pickYear: selectedSendingPick.year,
            receivingManagerId: parseInt(selectedReceiverId),
            sendingManagerId: originalManager?.id,
            viaManagerId: viaManager?.id ?? null
        }

        if (newTrade.sendingManagerId === null || newTrade.receivingManagerId === null || newTrade.pickYear === null || newTrade.pickRound === null) {
            console.info('Error: Draft pick trade failed validation');
            return false;
        }

        FantasyService.putDraftPickTrade(newTrade).then(res => {
            Alert.responseCode(res);
        }).then(_ => {
            setToggle(toggle + 1);
            setResetToggle(2);
        }).catch(err => {
            Logger.error(err);
        });
    }

    return (
        <>
            <label>Notes: </label>
            <input id={stepSubmitNotesInput} type={"text"} onChange={textHandler}/>

            <input id={stepSubmitConditionalInput} type={"checkbox"} onChange={checkHandler} defaultChecked={false}/>
            <label> Is Conditional?</label>

            <input type={"button"} onClick={buttonHandler} value={"Submit"}/>
        </>
    );
}

function resetStepSubmit(setNotes: ISetNotes, setConditional: ISetBool) {
    setNotes(null);
    let notesInput = document.getElementById(stepSubmitNotesInput) as HTMLInputElement;
    if (notesInput) {
        notesInput.value = "";
    }
    setConditional(false);
    let conditionalInput = document.getElementById(stepSubmitConditionalInput) as HTMLInputElement;
    if (conditionalInput) {
        conditionalInput.checked = false;
    }
}

function renderNewTradeReset(setResetToggle: ISetToggle) {

    const buttonHandler = (e: React.MouseEvent<HTMLInputElement>) => {
        setResetToggle(1);
    }

    return (
        <>
            <input id={resetButton} type={"button"} onClick={buttonHandler} value={"Reset"}/>
        </>
    );
}

function renderPicksTables(leagueId: string | undefined, year: string, toggle: number) {
    if (!leagueId) {
        return <></>
    }

    return (
        <>
            <DraftTablesComponent leagueId={leagueId} year={year} toggle={toggle}/>
            <PickTradesComponent leagueId={leagueId} year={year} toggle={toggle}/>
        </>
    );
}

interface ISetDraftPicks extends React.Dispatch<React.SetStateAction<MFantasyDraftPick[] | undefined>> { }
interface ISetSelectedPick extends React.Dispatch<React.SetStateAction<MFantasyDraftPick | undefined>> { }
interface ISetLeagues extends React.Dispatch<React.SetStateAction<MFantasyLeague[] | undefined>> { }
interface ISetSelectedLeagueId extends React.Dispatch<React.SetStateAction<string | undefined>> { }
interface ISetManagers extends React.Dispatch<React.SetStateAction<MFantasyManager[] | undefined>> { }
interface ISetNotes extends React.Dispatch<React.SetStateAction<string | null>> { }
interface ISetSelectedManagerId extends React.Dispatch<React.SetStateAction<string | undefined>> { }
interface ISetToggle extends React.Dispatch<React.SetStateAction<number>> { }
interface ISetYear extends React.Dispatch<React.SetStateAction<string | undefined>> { }
interface ISetBool extends React.Dispatch<React.SetStateAction<boolean>> { }

const AdminDraftPickTrades = () => {
    const [draftPicks, setDraftPicks] = useState<MFantasyDraftPick[]>();
    const [conditional, setConditional] = useState<boolean>(false);
    const [selectedSendingPick, setSelectedSendingPick] = useState<MFantasyDraftPick>();
    const [leagues, setLeagues] = useState<MFantasyLeague[]>();
    const [selectedLeagueId, setSelectedLeagueId] = useState<string>();
    const [managers, setManagers] = useState<MFantasyManager[]>();
    const [notes, setNotes] = useState<string | null>(null);
    const [resetToggle, setResetToggle] = useState<number>(0);
    const [selectedReceiverId, setSelectedReceiverId] = useState<string>();
    const [selectedSenderId, setSelectedSenderId] = useState<string>();
    const [toggle, setToggle] = useState<number>(0);
    const [year, setYear] = useState<string>();

    useEffect(() => {
        fetchLeagues(leagues, setLeagues);
    }, []);

    useEffect(() => {
        !!selectedLeagueId && fetchManagers(selectedLeagueId, setManagers);
    }, [selectedLeagueId]);

    useEffect(() => {
        !!selectedLeagueId && !!selectedSenderId && !!year && fetchDraftPicks(selectedLeagueId, selectedSenderId, year, setDraftPicks);
    }, [selectedLeagueId, selectedSenderId, year]);

    useEffect(() => {
        if (resetToggle === 0) {
            return;
        }

        if (resetToggle <= 4) {
            resetStepSubmit(setNotes, setConditional);
        }
        if (resetToggle <= 3) {
            resetStepThree(setSelectedSendingPick, setSelectedReceiverId);
        }
        if (resetToggle <= 2) {
            resetStepTwo(setSelectedSenderId);
        }
        if (resetToggle <= 1) {
            resetStepOne(setSelectedLeagueId, setYear);
        }

        setResetToggle(0);
    }, [resetToggle]);

    return (
        <>
            { renderNewTradeReset(setResetToggle) }

            {!!leagues &&
                renderNewTradeStepOne(leagues, selectedLeagueId, setSelectedLeagueId, setYear)}
            {!!selectedLeagueId && !!year && !!managers &&
                renderNewTradeStepTwo(managers, setSelectedSenderId)}
            {!!selectedSenderId && !!draftPicks && !!managers &&
                renderNewTradeStepThree(selectedSenderId, draftPicks, setSelectedSendingPick, managers, setSelectedReceiverId)}
            {!!selectedSenderId && !!selectedSendingPick && !!selectedReceiverId &&
                renderNewTradeSubmit(selectedSenderId, selectedSendingPick, selectedReceiverId, notes, setNotes, conditional, setConditional, setYear, toggle, setToggle, setResetToggle)}

            { !!year && renderPicksTables(selectedLeagueId, year, toggle) }
        </>
    );
};

export default AdminDraftPickTrades;
