import React from "react";
import {firebaseAuth} from "../../../firebase";
import {UserContext} from "../../context/UserContext";
import {Navigate, useSearchParams} from "react-router-dom";

const LoggingInComponent = () => {
    const [search] = useSearchParams();

    return (
        <UserContext.Consumer>
            {({user, setUser}) => {
                if (!!user) {
                    return <Navigate to={search.get('redirect') ?? '/'}/>
                }

                return (
                    <div>
                        <h1>logging-in</h1>
                        <div>Name: {firebaseAuth.currentUser?.displayName}</div>
                        <div>firebaseUid: {String(firebaseAuth.currentUser?.uid)}</div>
                    </div>
                )
            }}
        </UserContext.Consumer>
    );
};

export default LoggingInComponent;
