import React, {useEffect, useState} from "react";
import {MFantasyDraftPick} from "../../../model/entities/MFantasyDraftPick";
import {MFantasyDraftPickTrade} from "../../../model/entities/MFantasyDraftPickTrade";
import {FantasyLeaguesService} from "../../../service/FantasyLeaguesService";

function fetchDraftOrder(leagueId: string, year: string, setPicks: ISetPicks, setYear: ISetYear) {
    FantasyLeaguesService.getDraftPicks(leagueId, 3, year).then(response => response.json()).then((json: MFantasyDraftPick[]) => {
        setPicks(json);
        setYear(json?.at(0)?.year.toString());
    });
}

function renderDraftPicks(picks: MFantasyDraftPick[] | undefined, year: string | undefined) {
    if (!picks) {
        return <></>
    }

    return (
        <>
            <table>
                <caption>{year ?? ''} Picks List</caption>
                <thead>
                <tr>
                    <th>Year</th>
                    <th>Round</th>
                    <th>Pick</th>
                    <th>Owner</th>
                    <th>Trade</th>
                </tr>
                </thead>
                <tbody>
                {picks.map((item: MFantasyDraftPick, i: number) => (
                    <tr key={i}>
                        <td>{item.year}</td>
                        <td>{item.round}</td>
                        <td>{item.pickNumber}{!item.standing.lockedIn && <sup>*</sup>}</td>
                        <td>{item.manager.displayName}</td>
                        <td>{renderDraftPickTrade(item.draftPickTrade)}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div>* - Pick estimated and not locked in by standings</div>
        </>
    )
}

function renderDraftPickTrade(trade: MFantasyDraftPickTrade) {
    if (!!trade) {
        let output = "From " + trade.sendingManager.displayName;
        if (!!trade.viaManager) {
            output += " (via " + trade.viaManager.displayName + ")";
        }
        return output;
    } else {
        return null
    }
}

interface PDraftTables {
    leagueId: string
    toggle: number
    year: string
}

interface ISetPicks extends React.Dispatch<React.SetStateAction<MFantasyDraftPick[] | undefined>> { }
interface ISetYear extends React.Dispatch<React.SetStateAction<string | undefined>> { }

const DraftTablesComponent = (props: PDraftTables) => {
    // eslint-disable-next-line
    const [leagueId, setLeagueId] = useState<string>();
    // eslint-disable-next-line
    const [toggle, setToggle] = useState<number>(0);
    const [picks, setPicks] = useState<MFantasyDraftPick[]>();
    const [year, setYear] = useState<string>();

    useEffect(() => {
        setLeagueId(props.leagueId);
        setToggle(props.toggle);
        setYear(props.year);

        fetchDraftOrder(props.leagueId!, props.year!, setPicks, setYear);
    }, [props.leagueId, props.year, props.toggle]);

    return (
        <>
            {!picks && <div>Loading...</div>}
            {renderDraftPicks(picks, year)}
        </>
    )
};

export default DraftTablesComponent;
