import ReactDOM from "react-dom/client";
import "./scss/main.scss";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from "./ts/view/layout/Layout";
import Home from "./ts/view/Home";
import Fantasy from "./ts/view/fantasy/Fantasy";
import FantasyLeague from "./ts/view/fantasy/FantasyLeague";
import FantasyLeagueManagers from "./ts/view/fantasy/FantasyLeagueManagers";
import NoPage from "./ts/view/NoPage";
import reportWebVitals from "./reportWebVitals";
import FantasyLeagueBylaws from "./ts/view/fantasy/FantasyLeagueBylaws";
import FantasyLeagueRecordBook from "./ts/view/fantasy/FantasyLeagueRecordBook";
import FantasyLeagueDraft from "./ts/view/fantasy/FantasyLeagueDraft";
import FantasyLeagueWeekRecaps from "./ts/view/fantasy/FantasyLeagueWeekRecaps";
import Admin from "./ts/view/admin/Admin";
import AdminDraftPickTrades from "./ts/view/admin/AdminDraftPickTrades";
import AdminLeagueSettings from "./ts/view/admin/AdminLeagueSettings";
import {PrivateRoute, ROLE} from "./ts/view/PrivateRoute";
import AuthenticationComponent from "./ts/view/components/Authentication.component";
import LoggingInComponent from "./ts/view/components/LoggingIn.component";
import {loginUser, logoutUser, UserContext} from "./ts/context/UserContext";
import {useEffect, useState} from "react";
import {firebaseAuth} from "./firebase";
import {Logger} from "./Logger";
import LayoutRoute from "./ts/view/layout/LayoutRoute";
import AccessDenied from "./ts/view/AccessDenied";
import FantasyLeagueHistory from "./ts/view/fantasy/FantasyLeagueHistory";

export default function App() {
    const [user, setUser] = useState(null);
    const userValue = { user, setUser };

    useEffect(() => {
        firebaseAuth.onAuthStateChanged((fbUser) => {
            if (!!fbUser && !user) {
                loginUser(fbUser, setUser)
                    .then(_ => Logger.debug('index submitted log-in'));
            } else if (!fbUser && !!user) {
                logoutUser(setUser)
                    .then(_ => Logger.debug('index submitted log-out'));
            }
        });
    }, []);

    return (
        <UserContext.Provider value={userValue}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />

                        <Route path="/fantasy" element={<Fantasy />} />
                        <Route path="/fantasy/add-league" element={<AccessDenied />} />
                        <Route path="/fantasy/:leagueId" element={<LayoutRoute />}>
                            <Route index element={<FantasyLeague />} />
                            <Route path="bylaws" element={<FantasyLeagueBylaws />} />
                            <Route path="draft" element={<FantasyLeagueDraft />} />
                            <Route path="draft/:year" element={<FantasyLeagueDraft />} />
                            <Route path="history" element={<FantasyLeagueHistory />} />
                            <Route path="managers" element={<FantasyLeagueManagers />} />
                            <Route path="managers/:managerId" element={<FantasyLeagueManagers />} />
                            <Route path="record-book" element={<FantasyLeagueRecordBook />} />
                            <Route path="week-recaps" element={<FantasyLeagueWeekRecaps />} />
                            <Route path="week-recaps/:year/:week" element={<FantasyLeagueWeekRecaps />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>

                        <Route path="/log-in" element={<AuthenticationComponent />} />
                        <Route path="/logging-in" element={<LoggingInComponent />} />

                        <Route path="/admin" element={<PrivateRoute component={LayoutRoute} roles={[ROLE.Admin]} />}>
                            <Route index element={<Admin />} />
                            <Route path="draft-pick-trades" element={<AdminDraftPickTrades />} />
                            <Route path="draft-pick-trades/:leagueId" element={<AdminDraftPickTrades />} />
                            <Route path="draft-pick-trades/:leagueId/:year" element={<AdminDraftPickTrades />} />
                            <Route path="league-settings" element={<AdminLeagueSettings />} />
                            <Route path="private" element={<PrivateRoute component={AdminLeagueSettings} roles={[ROLE.Admin]} />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>

                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
