import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {MFantasyLeague} from "../../../model/entities/MFantasyLeague";
import {FantasyLeaguesService} from "../../../service/FantasyLeaguesService";
import MenuButtonComponent from "../../layout/components/MenuButtonComponent";
import PopupMenuComponent from "../../components/PopupMenu.component";


const FantasyLeagueNavComponent = () => {
    let navigate = useNavigate();
    let { leagueId } = useParams();
    const [league, setLeague] = useState<MFantasyLeague>();
    const [showMenu, setShowMenu] = useState<boolean>(false);

    useEffect(() => {
        if (leagueId !== undefined && (league === undefined || leagueId !== league.id.toString())) {
            FantasyLeaguesService.getLeague(leagueId).then(response => response.json()).then(json => {
                setLeague(json);
            });
        } else {
            setLeague(undefined);
        }
    }, [leagueId]);

    const fantasyRoute = `/fantasy`;
    const homeRoute = `/fantasy/${leagueId}`;
    const bylawsRoute = `/fantasy/${leagueId}/bylaws`;
    const draftBoardRoute = `/fantasy/${leagueId}/draft`;
    const historyRoute = `/fantasy/${leagueId}/history`;
    const managerRoute = `/fantasy/${leagueId}/managers`;
    const recordBookRoute = `/fantasy/${leagueId}/record-book`;
    const weekRecapRoute = `/fantasy/${leagueId}/week-recaps`;

    const hoverClassName = "hover-white-secondary";

    const clickHandler = (route: string) => {
        navigate(route);
        setShowMenu(false);
    }

    function nodeClassName(popup: boolean) {
        return `${popup ? '' : 'submenu-full'} ${hoverClassName}`;
    }

    const bylawsNode = (popup: boolean) => league?.hasBylaws &&
        <MenuButtonComponent className={nodeClassName(popup)} onClick={() => clickHandler(bylawsRoute)} content={<div>Bylaws</div>} routeRegex={/^\/fantasy\/\d\/bylaws$/}/>
    const draftNode = (popup: boolean) => league?.hasDraftBoard &&
        <MenuButtonComponent className={nodeClassName(popup)} onClick={() => clickHandler(draftBoardRoute)} content={<div>Draft</div>} routeRegex={/^\/fantasy\/\d\/draft$/}/>
    const historyNode = (popup: boolean) => league?.hasRecordBook &&
        <MenuButtonComponent className={nodeClassName(popup)} onClick={() => clickHandler(historyRoute)} content={<div>History</div>} routeRegex={/^\/fantasy\/\d\/history$/}/>
    const managerNode = (popup: boolean) => league?.hasManagers &&
        <MenuButtonComponent className={nodeClassName(popup)} onClick={() => clickHandler(managerRoute)} content={<div>Managers</div>} routeRegex={/^\/fantasy\/\d\/managers/}/>
    const recordsNode = (popup: boolean) => league?.hasRecordBook &&
        <MenuButtonComponent className={nodeClassName(popup)} onClick={() => clickHandler(recordBookRoute)} content={<div>Records</div>} routeRegex={/^\/fantasy\/\d\/record-book$/}/>
    const weeksNode = (popup: boolean) => league?.hasWeekRecaps &&
        <MenuButtonComponent className={nodeClassName(popup)} onClick={() => clickHandler(weekRecapRoute)} content={<div>Weeks</div>} routeRegex={/^\/fantasy\/\d\/week-recaps$/}/>

    return (
        <ul>
            {!!league &&
                <MenuButtonComponent className={hoverClassName} onClick={() => navigate(homeRoute)} content={<div>{league.name}</div>} routeRegex={/^\/fantasy\/\d$/}/>
            }
            {!league &&
                <>
                    <MenuButtonComponent className={hoverClassName} onClick={() => navigate(fantasyRoute)} content={<div>Leagues</div>} routeRegex={/^\/fantasy$/}/>
                </>
            }
            {bylawsNode(false)}
            {draftNode(false)}
            {historyNode(false)}
            {managerNode(false)}
            {recordsNode(false)}
            {weeksNode(false)}
            {!!league &&
                <MenuButtonComponent className={`submenu-small ${hoverClassName}`} onClick={() => setShowMenu(!showMenu)} content={<div>More</div>} routeRegex={null}/>
            }
            {showMenu && !!league &&
                <PopupMenuComponent className={'menu-submenu-popup'} setShowMenu={setShowMenu} nodes={[
                    bylawsNode(true),
                    draftNode(true),
                    historyNode(true),
                    managerNode(true),
                    recordsNode(true),
                    weeksNode(true)
                ]}/>
            }
        </ul>
    );
};

export default FantasyLeagueNavComponent;
