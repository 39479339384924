import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import {firebaseAuth} from "../../../firebase";
import {useEffect} from "react";
import {useSearchParams} from "react-router-dom";

const AuthenticationComponent = () => {
    const [search] = useSearchParams();

    var uiConfig = {
        signInSuccessUrl: '/logging-in?redirect=' + search.get('redirect') ?? '/',
        signInOptions: [
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                disableSignUp: {
                    status: process.env.REACT_APP_FIREBASE_ENABLE_SIGN_UP !== 'true'
                }
            }
        ]
        // tosUrl and privacyPolicyUrl accept either url string or a callback function.
        // tosUrl: '<your-tos-url>',
        // privacyPolicyUrl: function() {
        //     window.location.assign('<your-privacy-policy-url>');
        // }
    };

    useEffect(() => {
        var ui = firebaseui.auth.AuthUI.getInstance();
        if (!ui) {
            ui = new firebaseui.auth.AuthUI(firebaseAuth);
        }
        ui.start('#firebaseui-auth-container', uiConfig);
    }, []);

    return (
        <>
            <h1>log-in</h1>
            <div id="firebaseui-auth-container"></div>
        </>
    );
};

export default AuthenticationComponent;
