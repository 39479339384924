import {MFantasyLeague} from "../../../model/entities/MFantasyLeague";
import React, {useEffect, useState} from "react";
import {FantasyLeaguesService} from "../../../service/FantasyLeaguesService";
import {MFantasyStanding} from "../../../model/entities/MFantasyStanding";
import {Logger} from "../../../../Logger";
import {StandingBody} from "../../../model/requests/StandingBody";

interface ISetStandingsProps {
    league: MFantasyLeague | undefined,
    initYear: number | undefined
}

function fetchStandings(league: MFantasyLeague, year: string, setStandings: any) {
    setStandings(undefined);
    FantasyLeaguesService.getStandingsYear(league.id.toString(), year).then(response => response.json()).then(json => {
        setStandings(json);
    });
}

function updateStandings(league: MFantasyLeague, year: string, body: StandingBody[], setStandings: any) {
    setStandings(undefined);
    FantasyLeaguesService.putStandingsYear(league.id.toString(), year, body).then(response => response.json()).then(json => {
        setStandings(json);
    });
}

function renderStandingsTable(league: MFantasyLeague, standings: MFantasyStanding[], setStandings: any) {

    const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let standingsSelects = document.getElementsByClassName("standings-select");


        for (let i = 0; i < standingsSelects.length; i++) {
            let standingSelect = standingsSelects.item(i)! as HTMLSelectElement;

            if (standingSelect.value === e.target.value && standingSelect !== e.target) {
                standingSelect.value = "-";
            }
        }
    }

    const updateHandler = () => {
        let updateYear = document.getElementById("update-year") as HTMLInputElement;
        let standingsSelects = document.getElementsByClassName("standings-select");
        let standingsLocks = document.getElementsByClassName("standings-locked");

        let standingsBody: StandingBody[] = [];
        let usedNumbers: string[] = [];

        for (let i = 0; i < standingsSelects.length; i++) {
            let standingSelect = standingsSelects.item(i) as HTMLSelectElement
            if (standingSelect.value === "-" || usedNumbers.includes(standingSelect.value)) {
                Logger.info("Invalid standing: " + standingSelect.value);
                alert("Unable to update: Invalid standings");
                return;
            }

            let standingLock = standingsLocks.item(i) as HTMLInputElement;

            standingsBody.push({
                lockedIn: standingLock.checked,
                managerId: parseInt(standingsSelects.item(i)!.id),
                managerTeamId: null,
                standing: parseInt(standingSelect.value)
            });

            usedNumbers.push(standingSelect.value);
        }

        updateStandings(league, updateYear.value, standingsBody, setStandings);
    }

    return (
        <>
            {!!standings && standings.length > 0 &&
                <table>
                    <thead>
                    <tr>
                        <th>Year</th>
                        <th>Team</th>
                        <th>Standing</th>
                        <th>New Standing</th>
                        <th>Locked In</th>
                    </tr>
                    </thead>
                    <tbody>
                    {standings.map((stand: MFantasyStanding) => {
                        return (
                            <tr>
                                <td>{stand.year}</td>
                                <td>{stand.managerTeam.name}</td>
                                <td>{stand.standing}</td>
                                <td>
                                    <select className={"standings-select"} onChange={selectHandler} id={stand.managerTeam.manager.id.toString()}>
                                        <option id={`${stand.managerTeam.manager.id}-0`} value={"-"}>-</option>
                                        {standings.map((s: MFantasyStanding) => {
                                            return <option
                                                id={`${stand.managerTeam.manager.id}-${s.standing}`}
                                                key={`${stand.managerTeam.manager.id}-${s.standing}`}
                                                value={s.standing}
                                                selected={s.standing === stand.standing}>
                                                    {s.standing}
                                            </option>
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <input className={"standings-locked"} type={"checkbox"} defaultChecked={stand.lockedIn}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            }
            <form>
                <input id={"update-year"} type={"text"} />
                <input type={"button"} onClick={updateHandler} value={"UPDATE"} />
            </form>
        </>
    )
}

const SetStandingsComponent = (props: ISetStandingsProps) => {
    const { league, initYear } = props;

    const [standings, setStandings] = useState<MFantasyStanding[]>()
    const [year, setYear] = useState<number>();

    useEffect(() => {
        setYear(initYear);
    }, [initYear])

    useEffect(() => {
        if (!!league && !!year) {
            fetchStandings(league, year.toString(), setStandings);
        }
    }, [league, year]);

    useEffect(() => {
        let updateYear = document.getElementById("update-year") as HTMLInputElement;
        if (!!updateYear && !!year) {
            updateYear.value = year.toString();
        }
    }, [standings])

    const loadHandler = () => {
        let loadYear = document.getElementById("load-year") as HTMLInputElement;
        setYear(parseInt(loadYear.value));
        loadYear.value = "";
    }

    return (
        <>
            <h3>Standings</h3>

            <form>
                <input id={"load-year"} type={"text"} />
                <input type={"button"} onClick={loadHandler} value={"LOAD"} />
            </form>

            { standings && !!league && renderStandingsTable(league, standings, setStandings) }
        </>
    );
}

export default SetStandingsComponent;
