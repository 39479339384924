import {MFantasyLeague} from "../../../model/entities/MFantasyLeague";
import React from "react";

interface ISetSelected extends React.Dispatch<React.SetStateAction<MFantasyLeague | undefined>> { }
interface ILeagueSelectProps {
    leagues: MFantasyLeague[] | undefined,
    setSelected: ISetSelected | null
}

const LeagueSelectComponent = (props: ILeagueSelectProps) => {
    const { leagues, setSelected } = props;

    const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelected!(leagues!.filter(l => l.id.toString() === e.target.value).at(0)!);
    };

    if (leagues === undefined || setSelected === undefined) {
        return <></>;
    }

    return (
        <div>
            <select onChange={selectHandler}>
                <option disabled={true} key={null} selected={true} value={undefined}>Select League</option>
                {leagues.map((item: MFantasyLeague) => {
                    return <option key ={item.id} value={item.id}>{item.name} ({item.id})</option>
                })}
            </select>
        </div>
    );
}

export default LeagueSelectComponent;
