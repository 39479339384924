import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import {MFantasyRecordsIdvSummaryResponse} from "../../model/responses/MFantasyRecordsIdvSummaryResponse";
import {sortTable} from "../../utilities";
import {MFantasyStandingsYearResponse} from "../../model/responses/MFantasyStandingsYearResponse";
import {MFantasyRecordsIdvYearRecap} from "../../model/responses/MFantasyRecordsIdvYearRecap";
import {MFantasyManager} from "../../model/entities/MFantasyManager";
import PlaceholderTableComponent from "../placeholders/PlaceholderTable.component";

function fetchManagerHistory(leagueId: string, setMatchupResponse: ISetManagerHistory) {
    FantasyLeaguesService.getLeagueRecordBookManagerRecords(leagueId).then(response => response.json()).then(json => {
        setMatchupResponse(json);
    });
}

function fetchManagers(leagueId: string, setManagers: ISetManagers) {
    FantasyLeaguesService.getAllManagers(leagueId).then(response => response.json()).then(json => {
        setManagers(json);
    });
}

function fetchSeasons(leagueId: string, setSeasons: ISetSeasons) {
    FantasyLeaguesService.getSeasonRecaps(leagueId).then(response => response.json()).then(json => {
        setSeasons(json);
    });
}

function fetchStandings(leagueId: string, setStandings: ISetStandings) {
    FantasyLeaguesService.getStandings(leagueId).then(response => response.json()).then(json => {
        setStandings(json);
    });
}

function renderChampionsTable(seasons: MFantasyRecordsIdvYearRecap[] | undefined) {
    if (!seasons) {
        return (
            <></>
        )
    } else {
        let champSeasons = seasons.filter(s => s.wonChampionship)
            .sort((a, b) => a.year > b.year ? 1 : -1);
        let hasTies = champSeasons.filter(s => s.records.overallRecord.ties > 0).length > 0;
        return (
            <>
                <table id={'table-champions'}>
                    <caption>Champions</caption>
                    <thead>
                        <tr>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 0, true)}>Year</th>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 1, false)}>Manager</th>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 2, false)}>Team</th>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 3, true)}>Wins</th>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 4, true)}>Losses</th>
                            {<th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 5, true)}>Ties</th> && hasTies}
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 5 + (hasTies ? 1 : 0), true)}>WPct</th>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 6 + (hasTies ? 1 : 0), true)}>PF/G</th>
                            <th className={'sorted-any'} onClick={() => sortTable(`table-champions`, 7 + (hasTies ? 1 : 0), true)}>PA/G</th>
                        </tr>
                    </thead>
                    <tbody>
                    {champSeasons.map((item: MFantasyRecordsIdvYearRecap) => (
                        <tr>
                            <td>
                                {item.year}
                            </td>
                            <td>
                                {item.team.manager.displayName}
                            </td>
                            <td>
                                {item.team.name}
                            </td>
                            <td>
                                {item.records.overallRecord.wins}
                            </td>
                            <td>
                                {item.records.overallRecord.losses}
                            </td>
                            {<td>
                                {item.records.overallRecord.ties}
                            </td> && hasTies}
                            <td>
                                {item.records.overallRecord.winPercent.toFixed(3)}
                            </td>
                            <td>
                                {item.records.overallRecord.averagePointsFor.toFixed(2)}
                            </td>
                            <td>
                                {item.records.overallRecord.averagePointsAgainst.toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

function renderSeasonFinishesTable(managers: MFantasyManager[] | undefined, standingYears: MFantasyStandingsYearResponse[] | undefined) {
    if (!managers || !standingYears) {
        return (
            <></>
        )
    } else {
        let years = standingYears.map(s => s.year);

        let managerMap = new Map();
        standingYears.forEach(sy => {
            sy.standings.forEach(s => {
                if (!managerMap.has(s.managerTeam.manager.id)) {
                    managerMap.set(s.managerTeam.manager.id, new Map());
                }

                managerMap.get(s.managerTeam.manager.id).set(sy.year, s.standing);
            })
        });
        return (
            <>
                <table id={'table-standings'}>
                    <caption>Standings</caption>
                    <thead>
                        <tr>
                            <th className={'sorted-any'} onClick={() => sortTable('table-standings', 0, false)}>Manager</th>
                            {years.map((y: number, i: number) => (
                                <th className={'sorted-any'} onClick={() => sortTable('table-standings', i + 1, true)}>'{y.toString().substring(2)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {managers.map((m: MFantasyManager) => (
                        managerMap.has(m.id) &&
                            <tr>
                                <td>{m.displayName}</td>
                                {years.map((y: number) => (
                                    <td>{managerMap.get(m.id).has(y) ? managerMap.get(m.id).get(y) : ''}</td>
                                ))}
                            </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }
}

const careerStateTable = (title: string, recordKey: string, content: MFantasyRecordsIdvSummaryResponse[]) => {
    return (
        <>
            <table id={`table-${title}`}>
                <caption>{title}</caption>
                <thead>
                    <tr>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 0, false)}>Manager</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 1, true)}>Wins</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 2, true)}>Losses</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 3, true)}>Ties</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 4, true)}>Total</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 5, true)}>Pct</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 6, true)}>PF/G</th>
                        <th className={'sorted-any'} onClick={() => sortTable(`table-${title}`, 7, true)}>PA/G</th>
                    </tr>
                </thead>
                <tbody>
                {content.map((item: MFantasyRecordsIdvSummaryResponse | any, i: number) => (
                    <tr>
                        <td>
                            {item.manager.displayName}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].wins}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].losses}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].ties}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].totalGames}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].winPercent.toFixed(3)}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].averagePointsFor.toFixed(2)}
                        </td>
                        <td className={'right-cell'}>
                            {item[recordKey].averagePointsAgainst.toFixed(2)}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    );
}

function renderManagerHistory(managerHistory: MFantasyRecordsIdvSummaryResponse[] | undefined) {
    if (!managerHistory) {
        return (
            <></>
        )
    } else {
        return (
            <>
                {careerStateTable("Overall Career Stats", "overallRecord", managerHistory)}
                {careerStateTable("Regular Season Career Stats", "regularSeasonRecord", managerHistory)}
                {careerStateTable("Playoff Career Stats", "playoffRecord", managerHistory)}
                {careerStateTable("Championship Career Stats", "championshipRecord", managerHistory)}
            </>
        )
    }
}

interface ISetManagerHistory extends React.Dispatch<React.SetStateAction<MFantasyRecordsIdvSummaryResponse[] | undefined>> { }
interface ISetManagers extends React.Dispatch<React.SetStateAction<MFantasyManager[] | undefined>> { }
interface ISetSeasons extends React.Dispatch<React.SetStateAction<MFantasyRecordsIdvYearRecap[] | undefined>> { }
interface ISetStandings extends React.Dispatch<React.SetStateAction<MFantasyStandingsYearResponse[] | undefined>> { }

const FantasyLeagueHistory = () => {
    let { leagueId } = useParams();
    const [managerHistory, setManagerHistory] = useState<MFantasyRecordsIdvSummaryResponse[]>();
    const [managers, setManagers] = useState<MFantasyManager[]>();
    const [seasons, setSeasons] = useState<MFantasyRecordsIdvYearRecap[]>();
    const [standingYears, setStandingYears] = useState<MFantasyStandingsYearResponse[]>();

    useEffect(() => {
        fetchManagerHistory(leagueId!, setManagerHistory);
        fetchManagers(leagueId!, setManagers);
        fetchSeasons(leagueId!, setSeasons);
        fetchStandings(leagueId!, setStandingYears);
    }, [leagueId]);

    return (
        <>
            <>
                <h2>League History</h2>
                <PlaceholderTableComponent content={renderChampionsTable(seasons)} ready={!!seasons}/>
                <PlaceholderTableComponent content={renderSeasonFinishesTable(managers, standingYears)} ready={!!managers && !!standingYears}/>
                <PlaceholderTableComponent content={renderManagerHistory(managerHistory)} ready={!!managerHistory}/>
            </>
        </>
    );
};

export default FantasyLeagueHistory;
