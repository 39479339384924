import React, {useEffect, useState} from 'react';
import {NavigateFunction, useNavigate} from "react-router-dom";
import {MFantasyLeague} from "../../model/entities/MFantasyLeague";
import {FantasyLeaguesService} from "../../service/FantasyLeaguesService";
import MenuButtonComponent from "../layout/components/MenuButtonComponent";
import PlaceholderListComponent from "../placeholders/PlaceholderList.component";


interface IFantasy {
    isLoaded: boolean;
    leagues: MFantasyLeague[];
}
interface ISetFantasy extends React.Dispatch<React.SetStateAction<IFantasy>> { }

function fetchLeagues(setState: ISetFantasy) {
    setState({
        isLoaded: false,
        leagues: []
    });

    FantasyLeaguesService.getAllLeagues().then(response => response.json()).then(json => {
        setState({
            isLoaded: true,
            leagues: json
        });
    });
}

function renderList(state: IFantasy, navigate: NavigateFunction) {
    function showDisplay(league: MFantasyLeague) {
        return league.hasBylaws || league.hasDraftBoard || league.hasManagers || league.hasRecordBook || league.hasWeekRecaps;
    }

    if (!state.isLoaded) {
        return (
            <div>Loading...</div>
        );
    } else {
        return (
            <ul className={'content-menu'}>
                {state.leagues.map((item: MFantasyLeague) => (
                    <>
                        {showDisplay(item) &&
                            <MenuButtonComponent
                                className={'hover-white-secondary'}
                                content={<div>{item.name}</div>}
                                onClick={() => navigate("/fantasy/" + item.id)}
                                routeRegex={null}
                            />
                        }
                    </>
                ))}
            </ul>
        );
    }
}

const Fantasy = () => {
    const navigate = useNavigate();
    const [state, setState] = useState<IFantasy>({
        isLoaded: false,
        leagues: []
    });

    useEffect(() => {
        fetchLeagues(setState);
    }, []);

    return (
        <>
            <h1>Fantasy</h1>

            <div>
                <PlaceholderListComponent content={renderList(state, navigate)} ready={state.isLoaded}/>
            </div>
        </>
    );
}
// }

export default Fantasy;
