import React, {useEffect, useState} from "react";
import {MFantasyDraftPickTrade} from "../../../model/entities/MFantasyDraftPickTrade";
import {FantasyService} from "../../../service/FantasyService";

function fetchPickTrades(leagueId: string, startYear: string, setPicks: ISetPicks, setStartYear: ISetYear, setEndYear: ISetYear) {
    FantasyService.getDraftPickTrades(leagueId, startYear, null).then(response => response.json()).then((json: MFantasyDraftPickTrade[]) => {
        setPicks(json);
        if (!!json) {
            setStartYear(json?.at(0)?.sendingPickYear.toString());
        }
        if (!!json) {
            setEndYear(json?.at(json.length-1)?.sendingPickYear.toString());
        }
    });
}

function renderPickTrades(trades: MFantasyDraftPickTrade[] | undefined, startYear: string | undefined, endYear: string | undefined) {
    if (!trades) {
        return <></>
    }

    return (
        <>
            <table>
                <caption>{startYear ?? ''}{startYear !== endYear ? '-' : ''}{endYear && startYear !== endYear ? endYear : ''} Trade List</caption>
                <thead>
                <tr>
                    <th>Sender</th>
                    <th>Year</th>
                    <th>Round</th>
                    <th>Recipient</th>
                </tr>
                </thead>
                <tbody>
                {trades.map((item: MFantasyDraftPickTrade, i: number) => (
                    <tr key={i}>
                        <td>{item.sendingManager.displayName}</td>
                        <td>{item.sendingPickYear}</td>
                        <td>{item.sendingPickRound}</td>
                        <td>{item.receivingManager.displayName}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </>
    )
}

interface PPickTables {
    leagueId: string
    toggle: number
    year: string
}

interface ISetPicks extends React.Dispatch<React.SetStateAction<MFantasyDraftPickTrade[] | undefined>> { }
interface ISetYear extends React.Dispatch<React.SetStateAction<string | undefined>> { }

const PickTradesComponent = (props: PPickTables) => {
    // eslint-disable-next-line
    const [leagueId, setLeagueId] = useState<string>();
    // eslint-disable-next-line
    const [toggle, setToggle] = useState<number>(0);
    const [trades, setTrades] = useState<MFantasyDraftPickTrade[]>();
    const [startYear, setStartYear] = useState<string>();
    const [endYear, setEndYear] = useState<string>();

    useEffect(() => {
        setLeagueId(props.leagueId);
        setToggle(props.toggle);
        setStartYear(props.year);

        fetchPickTrades(props.leagueId!, props.year!, setTrades, setStartYear, setEndYear);
    }, [props.leagueId, props.year, props.toggle]);

    return (
        <>
            {!trades && <div>Loading...</div>}
            {renderPickTrades(trades, startYear, endYear)}
        </>
    )
};

export default PickTradesComponent;
